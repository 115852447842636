import React, { useState, useEffect } from 'react';
import { Typography, Box, Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Bar } from 'react-chartjs-2';

import axios from 'axios';
import Main_sidebar from "../Main_sidebar";
import LoadingHexagon from './LoadingHexagon';
import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
 
const Informe = () => {
    const [alerta, setAlerta] = useState(null);
    const [loading, setLoading] = useState(false);
    const [inmueblesData, setInmueblesData] = useState([]);
    const [notificadosData, setNotificadosData] = useState([]);
    const [inmueblesPendientes, setInmueblesPendientes] = useState([]);
    const [etapasData, setEtapasData] = useState([]);
    const [inmueblesEtapaData, setInmueblesEtapaData] = useState([]);

    useEffect(() => {
        document.title = "Estado actual Aleia";
        fetchEtapasData();
        fetchInmueblesEtapaData();
        fetchNotificadosData();
        fetchInmueblesData();
    }, []);

    const fetchInmueblesData = async () => {
        try {
            const response = await axios.get('https://aleia.in/registros-listar');
            const inmuebles = response.data.rows;
            setInmueblesData(inmuebles);
            crossData(inmuebles, notificadosData);
        } catch (error) {
            console.error("Error fetching inmuebles data", error);
        }
    };

    const fetchInmueblesEtapaData = async () => {
        try {
            const response = await axios.get('https://aleia.in/get-inmueble-etapa');
            setInmueblesEtapaData(response.data.rows);
        } catch (error) {
            console.error("Error fetching inmuebles etapa data", error);
        }
    };

    const fetchEtapasData = async () => {
        try {
            const response = await axios.get('https://aleia.in/gestion-etapas/listar-etapas');
            setEtapasData(response.data.rows);
        } catch (error) {
            console.error("Error fetching etapas data", error);
        }
    };

    const fetchNotificadosData = async () => {
        try {
            const response = await axios.get('https://aleia.in/get-notificados');
            setNotificadosData(response.data.rows);
        } catch (error) {
            console.error("Error fetching notificados data", error);
        }
    };

    const crossData = (inmuebles, notificados) => {
        if (inmuebles.length > 0 && notificados.length > 0) {
            const crossed = inmuebles.filter(inmueble => 
                notificados.some(notificado => notificado.inmueble === inmueble.id_inmueble)
            );
        }
    };

    const handleActualizarDias = async () => {
        setLoading(true);
        try {
            const response = await axios.post('https://aleia.in/actualizar-dias-restantes');
            if (response.data) {
                setAlerta(response.data.message);
                setInmueblesPendientes(response.data.inmuebles_pendientes);
            }
        } catch (error) {
            console.error("Hubo un error al actualizar los días restantes", error);
        } finally {
            setLoading(false);
        }
    };

    const handleDisminuirDias = async () => {
        setLoading(true);
        try {
            const response = await axios.post('https://aleia.in/disminuir-dias-restantes');
            if (response.data) {
                setAlerta(response.data.message);
            }
        } catch (error) {
            console.error("Hubo un error al disminuir los días restantes", error);
        } finally {
            setLoading(false);
        }
    };

    const calcularDiasRestantes = (plazoNotificacion) => {
        const fechaActual = new Date();
        const fechaPlazo = new Date(plazoNotificacion);
        const diffTime = Math.abs(fechaPlazo - fechaActual);
        const diasRestantes = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diasRestantes;
    };

    const etapaCounts = etapasData.map(etapa => {
        const inmueblesEnEtapa = inmueblesEtapaData.filter(inmueble => inmueble.etapa === etapa.id);
    
        const inmueblesPendientes = inmueblesEnEtapa.filter(inmueble =>
            !notificadosData.some(notificado => notificado.id_inmueble === inmueble.id_inmueble)
        );
    
        const inmueblesConDiasRestantes = notificadosData.filter(inmueble => inmueble.dias_restantes > 0)
        .map(inmueble => ({
            inmueble: inmueble.inmueble,
            dias_restantes: inmueble.dias_restantes
        }));
        
        // const inmueblesConDiasRestantes = inmueblesPendientes.map(inmueble => {
        //     // Supongo que 'plazo_notificacion' y 'dias_restantes' son parte de los datos del inmueble
        //     const diasRestantes = calcularDiasRestantes(inmueble.plazo_notificacion); 
        //     return { 
        //         inmuebleId: inmueble.id_inmueble, 
        //         diasRestantes 
        //     };
        // });
    
        return {
            etapa: etapa.etapa,
            inmueblesPendientes: inmueblesConDiasRestantes
        };
    });   

    const etapaCounts2 = etapasData.map(etapa => {
        const inmueblesEnEtapa = inmueblesEtapaData.filter(inmueble => inmueble.etapa === etapa.id);
    
        const inmueblesNotificados = inmueblesEnEtapa.filter(inmueble =>
            notificadosData.some(notificado => notificado.id_etapa === inmueble.etapa)
        );
    
        const inmueblesNoNotificados = inmueblesEnEtapa.filter(inmueble =>
            !notificadosData.some(notificado => notificado.id_etapa === inmueble.etapa)
        );
    
        return {
            etapa: etapa.etapa,
            total: inmueblesEnEtapa.length,
            notificados: inmueblesNotificados.length,
            noNotificados: inmueblesNoNotificados.length,
        };
    });
   
    const etapasChartData2 = {
        labels: etapaCounts.map(item => item.etapa), // Etiquetas con los nombres de las etapas
        datasets: [
            {
                label: 'Inmuebles No Notificados',
                data: etapaCounts.map(item => item.noNotificados),
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1
            },
            {
                label: 'Inmuebles Notificados',
                data: etapaCounts.map(item => item.notificados),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1
            },
        ]
    };
    
    // etapaCounts.sort((a, b) => a.etapa.localeCompare(b.etapa)); 
    const etapasChartData = {
        labels: etapaCounts.map(item => item.etapa),
        datasets: [{
            label: 'Inmuebles por Etapa',
            data: etapaCounts.map(item => item.count),
            backgroundColor: etapasData.map((_, index) => `rgba(${(index * 40) % 255}, ${index * 60 % 255}, 200, 0.6)`)
        }]
    };
    
    const diasRestantesData = notificadosData.filter(inmueble => inmueble.dias_restantes > 0)
    .map(inmueble => ({
        inmueble: inmueble.inmueble,
        dias_restantes: inmueble.dias_restantes
    }));

    const diasRestantesChartData = {
        labels: diasRestantesData.map(item => `${item.inmueble}`),
        datasets: [{
            label: 'Días Restantes',
            data: diasRestantesData.map(item => item.dias_restantes),
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
        }]
    };
    
    const chartOptions = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                max: 20
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
            }
        },
    };

    const renderEtapasCharts = () => {
        return etapaCounts.map((etapaData, index) => {
            const chartData = {
                labels: etapaData.inmueblesPendientes.map(inmueble => `Inmueble ${inmueble.inmuebleId}`), // Inmuebles en eje x
                datasets: [{
                    label: `Días restantes - ${etapaData.etapa}`,
                    data: etapaData.inmueblesPendientes.map(inmueble => inmueble.diasRestantes), // Días restantes en eje y
                    backgroundColor: 'rgba(54, 162, 235, 0.6)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1
                }]
            };
    
            return (
                <div key={index} style={{ margin: '20px 0' }}>
                    <h3>Etapa: {etapaData.etapa}</h3>
                    <Bar data={chartData} options={{ responsive: true }} />
                </div>
            );
        });
    };


    if (loading) {
        return <LoadingHexagon />;
    }

    return (
        <>
            <Main_sidebar />
            <Box className="config-container">
                <Grid item xs={12} textAlign="center" mb={0}>
                    <h2>Estado actual de Aleia:</h2>
                </Grid>
                <Grid container spacing={2} alignItems="center" mb={3}>
                    <Grid item xs={6} textAlign="right">
                        <Typography variant="h6">
                            {alerta ? alerta : "Inmuebles a notificar: pendiente"}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="left">
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleActualizarDias}
                        >
                            Actualizar Días Restantes
                        </Button>
                    </Grid>
                    <Grid item xs={6} textAlign="left">
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleDisminuirDias}
                            // style={{ backgroundColor: 'black', color: 'white' }}
                        >
                            Disminuir 1 día
                        </Button>
                    </Grid>
                </Grid>
                
                <Grid container spacing={4} mt={4} mr={15} ml={15} alignItems="center">
                    {/* <Grid item xs={12} md={10}>
                        <Bar data={etapasChartData} options={{ maintainAspectRatio: false }} />
                    </Grid> */}
                    
                    <Grid item xs={12} md={10}>
                        <Bar 
                            data={etapasChartData2} 
                            options={{
                                maintainAspectRatio: false,
                                scales: {
                                    y: {
                                        beginAtZero: true,
                                        max: Math.max(...etapaCounts.map(item => item.total)) // Ajusta el valor máximo del eje y
                                    }
                                }
                            }} 
                        />
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Bar 
                            data={diasRestantesChartData} 
                            options={chartOptions } 
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={10}>
                
                    <Typography variant="h5" component={'div'}>IInforme de Notificaciones por Etapa:</Typography>

                    {renderEtapasCharts()}
                </Grid>
                <Box mt={4} mr={15} ml={15}>
                    <Typography variant="h6">Inmuebles Pendientes:</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID Inmueble</TableCell>
                                    <TableCell>Etapa</TableCell>
                                    <TableCell>Archivo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {inmueblesPendientes.map((inmueble, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{inmueble.id_inmueble}</TableCell>
                                        <TableCell>{inmueble.etapa}</TableCell>
                                        <TableCell>{inmueble.archivo}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </>
    );
};

export default Informe;
