import React, { useState, useEffect } from 'react';
import { Grid, Modal, Box, TextField, Button, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import axios from 'axios';
import {styleModalDS} from "../css/modal"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const DatasetModal = ({ open, handleClose }) => {
  const [file, setFile] = useState(null);
  const [etapas, setEtapas] = useState([]);
  const [selectedEtapa, setSelectedEtapa] = useState('');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleEtapaChange = (event) => {
    setSelectedEtapa(event.target.value);
  };

  useEffect(() => {
    const fetchEtapas = async () => {
      try {
        const response = await axios.get('https://aleia.in/gestion-etapas/listar-etapas');
        setEtapas(Array.isArray(response.data.rows) ? response.data.rows : []);
      } catch (error) {
        console.error('Error fetching etapas:', error);
        setEtapas([]);
      }
    };

    fetchEtapas();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('etapa', selectedEtapa);

    try {
      const response = await axios.post('https://aleia.in/upload-dataset', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status === 200) {
        handleClose();
        alert('Se ha cargado la información exitosamente.');
        setFile(null);
        setSelectedEtapa('');
      }
    } catch (error) {
      console.error('Error uploading dataset:', error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={styleModalDS}>
        <Typography align='center' variant="h6" component="h2" mb={2}>
          Agregar Conjunto de Datos
        </Typography>
        <form onSubmit={handleSubmit}>
          <Button variant="contained" component="label" sx={{background:'black'}}>
            Seleccionar Archivo
            <input type="file" hidden onChange={handleFileChange} accept=".xls,.xlsx" />
          </Button>
          {file && <Typography variant="body1" mt={2}>{file.name}</Typography>}
          <Grid item xs={12} sm={4} mt={2}>
            <FormControl fullWidth>
              <InputLabel id="etapa-aso-label">Asignar etapa:</InputLabel>
              <Select
                labelId="etapa-aso-label"
                value={selectedEtapa}
                onChange={handleEtapaChange}
                label="Asignar etapa:"
              >
                <MenuItem value="">
                  <em>Ninguna</em>
                </MenuItem>
                {etapas.map((etapa) => (
                  <MenuItem key={etapa.id} value={etapa.id}>
                    {etapa.etapa}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Box mt={2} display="flex" justifyContent="center">
            <Button type="submit" variant="contained" color="primary" sx={{background:'black', mt: 2 }}>
              Subir
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default DatasetModal;
