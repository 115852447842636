import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  Grid,
  Box,
  TextField,
  Typography,
  Snackbar,
  Link,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { validationSchema } from '../js/validationSchema';
import { stLogin } from '../css/modal';
import { useNavigate } from 'react-router-dom'; 
import { AuthContext } from './AuthContext';

function FormRegistro({ handleClick }) {
  const { control, handleSubmit } = useForm();
  // const { control, handleSubmit, formState: { errors } } = useForm({
  //   resolver: yupResolver(validationSchema),
  // });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const onSubmit = async (data) => {
    setSnackbarMessage('');
    data['pwd'] = data['password'];
    try {
      const response = await axios.post('https://aleia.in/registro', data);
      setSnackbarMessage(response.data.message);
      setOpenSnackbar(true);
      handleClick();
      setCurrentView('login');
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.error('Error en la respuesta del servidor:', error.response.data);
        setSnackbarMessage(error.response.data.error || 'Error al registrar el usuario');
      } else {
        console.error('Error en la solicitud:', error.message);
        setSnackbarMessage('Error de conexión o de red');
      }
      setOpenSnackbar(true);
    }
  };

  return (
    <Box sx={stLogin.formBox1}>
      <Typography mt={4} variant="h4" align="center" gutterBottom>
        Registro de Usuario
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          {/* Campos del formulario de registro */}
          {['nombres', 'apellidos', 'correo', 'nid', 'telefono', 'area', 'cargo', 'nombre_usuario', 'password', 'confirm_password'].map((field, index) => (
            <Grid item xs={12} sm={index < 2 ? 6 : (index == 7 ) ? 12: (index > 7 ) ? 6: 4} key={field}>
              <Controller
                name={field}
                control={control}
                defaultValue=""
                render={({ field: controllerField }) => (
                  <TextField
                    {...controllerField}
                    label={capitalize(field.replace('_', ' '))}
                    variant="outlined"
                    fullWidth
                    type={field.includes('password') ? 'password' : 'text'}
                    margin="normal"
                    // error={Boolean(errors[field])}
                    // helperText={errors[field]?.message}
                    // FormHelperTextProps={{ style: stLogin.textProps }}
                  />
                )}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth sx={stLogin.buttonPrimary}>
              Registrarse
            </Button>
          </Grid>
        </Grid>
        <Box mb={2} justifyContent="center">
          <Grid item xs={12}>
            <Link component="button" variant="body2" onClick={handleClick} align="center" sx={stLogin.link}>
              Volver al Inicio de Sesión
            </Link>
          </Grid>
        </Box>
      </form>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)} message={snackbarMessage} />
    </Box>
  );
}

function FormRecuperarContra({ handleClick2 }) {
  const { control, handleSubmit} = useForm();
  // const { control, handleSubmit, formState: { errors } } = useForm({
  //   resolver: yupResolver(validationSchema),
  // });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const onSubmit1 = async (data) => {
    try {
      const response = await axios.post('https://aleia.in/recuperar', data);
      setSnackbarMessage(response.data.message);
      setOpenSnackbar(true);
      handleClick2();
    } catch (error) {
      setSnackbarMessage('Error en la recuperación de contraseña');
      setOpenSnackbar(true);
    }
  };

  return (
    <Box sx={stLogin.formBox2}>
      <Typography mt={4} variant="h5" align="center" gutterBottom>
        Recuperar Contraseña
      </Typography>
      <form onSubmit={handleSubmit(onSubmit1)} noValidate>
        <Grid container spacing={2}>
          {/* Campos del formulario de recuperación */}
          {['nombre_usuario', 'correo'].map((field) => (
            <Grid item xs={12} key={field}>
              <Controller
                name={field}
                control={control}
                defaultValue=""
                render={({ field: controllerField }) => (
                  <TextField
                    {...controllerField}
                    label={capitalize(field.replace('_', ' '))}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    // error={Boolean(errors[field])}
                    // helperText={errors[field]?.message}
                    // FormHelperTextProps={{ style: stLogin.textProps }}
                  />
                )}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth sx={stLogin.buttonPrimary}>
              Recuperar Contraseña
            </Button>
          </Grid>
        </Grid>
        <Box mb={2} justifyContent="center">
          <Grid item xs={12}>
            <Link component="button" variant="body2" onClick={handleClick2} align="center" sx={stLogin.link}>
              Volver al Inicio de Sesión
            </Link>
          </Grid>
        </Box>
      </form>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)} message={snackbarMessage} />
    </Box>
  );
}

function FormLogin({ handleClick }) {
  const [formData, setFormData] = useState({
    nombre_usuario: '',
    password: ''
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { login } = useContext(AuthContext); 
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
    
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
        const response = await axios.post('https://aleia.in/login', formData);
        if (response.status === 200) {
            const token = response.data.access_token; 
            login(token); 
            navigate('/Principal'); 
            // console.log('Inicio de sesión exitoso. Token recibido:', token);
        } else {
            console.warn('Inicio de sesión recibido con un código de estado distinto de 200:', response.status);
        }
        setSnackbarMessage(response.data.message);
        setOpenSnackbar(true);
      } catch (error) {
        if (error.response) {
            console.error('Error en la respuesta del servidor:', error.response);
            const errorMessage = error.response.status === 401 ? 
                                 'Usuario y/o contraseña incorrectos' : 
                                 'Error al iniciar sesión';
            setSnackbarMessage(errorMessage);
        } else if (error.request) {
            console.error('El servidor no respondió. Error de red o conexión:', error.request);
            setSnackbarMessage('Error de red o servidor no disponible.');
        } else {
            console.error('Error durante la solicitud:', error.message);
            setSnackbarMessage('Error inesperado durante el proceso.');
        }
        
        setFormData({ nombre_usuario: '', password: '' });
        setOpenSnackbar(true);
    }
  };

  return (
    <Box sx={stLogin.formBox3}>
      <Typography mt={4} variant="h4" align="center" gutterBottom>
        Iniciar Sesión
      </Typography>
      <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="nombre_usuario"
              label="Nombre de Usuario"
              variant="outlined"
              fullWidth
              value={formData.nombre_usuario}
              onChange={handleInputChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="password"
              label="Contraseña"
              type="password"
              variant="outlined"
              fullWidth
              value={formData.password}
              onChange={handleInputChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              fullWidth 
              sx={stLogin.buttonPrimary}
            >
              Ingresar
            </Button>
          </Grid>
        </Grid>
        <Box mb={2} display="flex" justifyContent="center">
          <Grid item xs={12} align="left">
            <Link component="button" variant="body2" onClick={() => handleClick('recuperar')} sx={stLogin.link}>
              ¿Olvidaste tu contraseña?
            </Link>
          </Grid>
          <Grid item xs={12} align="right">
            <Link component="button" variant="body2" onClick={() => handleClick('registro')} sx={stLogin.link}>
              Registrarse
            </Link>
          </Grid>
        </Box>
      </form>
      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={6000} 
        onClose={() => setOpenSnackbar(false)} 
        message={snackbarMessage}
      />
    </Box>
  );
}

export default function Login() {
  const [currentView, setCurrentView] = useState('login');

  const handleClick = (view) => setCurrentView(view);

  const { isAuthenticated, login } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
        const storedPath = localStorage.getItem('currentPath');
        navigate(storedPath);
        // console.log(localStorage);
    }
  }, [isAuthenticated, navigate]);

  return (
    <Grid container justifyContent="center" alignItems="center" minHeight="100vh" sx={{ backgroundColor: 'rgb(9 27 32)'}}>
      {currentView === 'login' && <FormLogin handleClick={handleClick} />}
      {currentView === 'registro' && <FormRegistro handleClick={() => handleClick('login')} />}
      {currentView === 'recuperar' && <FormRecuperarContra handleClick2={() => handleClick('login')} />}
      <img 
        src="/LOGO_ALEIA.png"
        alt="Logo" 
        style={{ 
          position: 'absolute', 
          bottom: '20px', 
          right: '20px', 
          width: '100px', 
          height: 'auto' 
        }} 
      />
    </Grid>
  );
}

function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

