import React, { useEffect, useState } from 'react';
import { Modal, TextField, Button, Grid, Tooltip, IconButton, Typography, Box, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import '../css/configGeneral.css'; 
import axios from 'axios';
import {styleModalConfigG} from "../css/modal"
import { useForm, Controller } from 'react-hook-form';

const ConfigGeneral = ({ open, handleClose}) => {
  const [configGen, setConfigGen] = useState(null);
  const [etapas, setEtapas] = useState([]);
  const { control, handleSubmit } = useForm();
  
  const [formData, setFormData] = useState({
    logotipo: null,
    logotipo_filename: '',
    acreedor: '',
    ciudad: '',
    nombre_rentas_ofi: '',
    nombre_ofi: '',
    dependencia_ofi: '',
    nombre_alcaldia: '',
    direccion_ofi: '',
    correo_ofi: '',
    correo_notif: '',
    telefono_ofi: '',
    pagina_ofi: '',
    hora_inicio: '',
    hora_cierre: '',
    nombre_jefe: '',
    pronombre_reporta: '',
    cargo_reporta: '',
    firma: null,
    firma_filename: '',
    disposicion_mp: '',
    limite_embargabilidad: '',
    c_numero_resolucion: '',
    c_numero_citacion: '',
    etapa_inicial: '',
    etapa_final: ''
  });
  const fetchConfigGen  = async () => {
    try {
      const configGenRequest = await axios.get('https://aleia.in/get-config-gen');
      setConfigGen(configGenRequest.data);
      const consultaEtapas = await axios.get('https://aleia.in/gestion-etapas/listar-etapas');
      setEtapas(Array.isArray(consultaEtapas.data.rows) ? consultaEtapas.data.rows : []);
    } catch (error) {
      console.error("Hubo un error al solicitar la configuración general", error);
    }
  };
  const formatTime = (time) => {
    if (!time) return '';
    const [hours, minutes] = time.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };
  
  useEffect(() => {
    if (configGen) {
      setFormData({
        logotipo: configGen.logotipo || null,
        logotipo_filename: configGen.logotipo_filename || '',
        acreedor: configGen.acreedor || '',
        ciudad: configGen.ciudad || '',
        nombre_rentas_ofi: configGen.nombre_rentas_ofi || '',
        nombre_ofi: configGen.nombre_ofi || '',
        dependencia_ofi: configGen.dependencia_ofi || '',
        nombre_alcaldia: configGen.nombre_alcaldia || '',
        direccion_ofi: configGen.direccion_ofi || '',
        correo_ofi: configGen.correo_ofi || '',
        correo_notif: configGen.correo_notif || '',
        telefono_ofi: configGen.telefono_ofi || '',
        pagina_ofi: configGen.pagina_ofi || '',
        hora_inicio: formatTime(configGen.hora_inicio) || '',
        hora_cierre: formatTime(configGen.hora_cierre) || '',
        nombre_jefe: configGen.nombre_jefe || '',
        pronombre_reporta: configGen.pronombre_reporta || '',
        cargo_reporta: configGen.cargo_reporta || '',
        firma: configGen.firma || null,
        firma_filename: configGen.firma_filename || '',
        disposicion_mp: configGen.disposicion_mp || '',
        limite_embargabilidad: configGen.limite_embargabilidad || '',
        c_numero_resolucion: configGen.c_numero_resolucion || '',
        c_numero_citacion: configGen.c_numero_citacion || '',
        etapa_inicial: configGen.etapa_inicial || '',
        etapa_final: configGen.etapa_final || ''
      });
    }
  }, [configGen]);
  const [etapaInicial, setEtapaInicial] = useState('');
  const [etapaFinal, setEtapaFinal] = useState('');
  
  const handleEtapaInicialChange = (event) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      etapa_inicial: value,  // actualizas etapa_inicial
    });
  };
  
  const handleEtapaFinalChange = (event) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      etapa_final: value,  // actualizas etapa_final
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
        [`${name}_filename`]: files[0].name,
      }));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();

    Object.keys(formData).forEach(key => {
      if (key === 'logotipo' || key === 'firma') {
        if (formData[key]) {
          form.append(key, formData[key]);
        }
      } else {
        form.append(key, formData[key]);
      }
    });
    try {
      console.log(formData);

      await axios.post('https://aleia.in/config-general-post', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      handleClose();
    } catch (error) {
      console.error('Ha sucedido un error!', error);
    }
  };
  
  useEffect(() => {
    if (open) {
      fetchConfigGen();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={styleModalConfigG}>
        <Typography align='center' variant="h5" component="div" className="config-header" mb={2}>
          Configuración parámetros generales
        </Typography>
        {configGen || 1? 
        <Box className="form-container">
          <form onSubmit={onSubmit}>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Ciudad"
                  name="ciudad"
                  value={formData.ciudad}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Nombre alcaldía"
                  name="nombre_alcaldia"
                  value={formData.nombre_alcaldia}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Tooltip title="por ejemplo: Subdirección de Tesorería Distrital">
                  <TextField
                    fullWidth
                    label="Dependencia"
                    name="dependencia_ofi"
                    value={formData.dependencia_ofi}
                    onChange={handleChange}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <Tooltip title="por ejemplo: Distrito Especial de Santiago de Cali">
                  <TextField
                    fullWidth
                    label="Nombre del acreedor"
                    name="acreedor"
                    value={formData.acreedor}
                    onChange={handleChange}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <Tooltip title="por ejemplo: Departamento Administrativo de Hacienda">
                  <TextField
                    fullWidth
                    label="Nombre de la oficina de rentas"
                    name="nombre_rentas_ofi"
                    value={formData.nombre_rentas_ofi}
                    onChange={handleChange}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <Tooltip title="por ejemplo: Oficina de Cobro Coactivo">
                  <TextField
                    fullWidth
                    label="Nombre de la oficina"
                    name="nombre_ofi"
                    value={formData.nombre_ofi}
                    onChange={handleChange}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Nombre del jefe de oficina"
                  name="nombre_jefe"
                  value={formData.nombre_jefe}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Pronombre"
                  name="pronombre_reporta"
                  value={formData.pronombre_reporta}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Cargo específico"
                  name="cargo_reporta"
                  value={formData.cargo_reporta}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="logotipo"
                  type="file"
                  name="logotipo"
                  onChange={handleImageChange}
                />
                <label htmlFor="logotipo">
                  Seleccionar Logotipo:
                  <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                  </IconButton>
                </label>
                {formData.logotipo && (typeof formData.logotipo === 'string' ? (
                  <img src={formData.logotipo} alt="Logotipo" style={{ width: '100px', height: '100px' }} />
                ) : (
                  <img src={URL.createObjectURL(formData.logotipo)} alt="Logotipo" style={{ width: '100px', height: '100px' }} />
                ))}
              </Grid>
              <Grid item xs={6}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="firma"
                  type="file"
                  name="firma"
                  onChange={handleImageChange}
                />
                <label htmlFor="firma">
                  Seleccionar Firma:
                  <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                  </IconButton>
                </label>
                {formData.firma && (typeof formData.firma === 'string' ? (
                  <img src={formData.firma} alt="Firma" style={{ width: '100px', height: '100px' }} />
                ) : (
                  <img src={URL.createObjectURL(formData.firma)} alt="Firma" style={{ width: '100px', height: '100px' }} />
                ))}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Correo oficina"
                  name="correo_ofi"
                  value={formData.correo_ofi}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Correo de notificaciones"
                  name="correo_notif"
                  value={formData.correo_notif}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label="Teléfono"
                  name="telefono_ofi"
                  value={formData.telefono_ofi}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label="Página"
                  name="pagina_ofi"
                  value={formData.pagina_ofi}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Dirección"
                  name="direccion_ofi"
                  value={formData.direccion_ofi}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2} className='labelCG'>
            
              <label htmlFor="horario">
                  Horario de atención:
              </label>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  type="time"
                  label="Hora inicio"
                  name="hora_inicio"
                  value={formData.hora_inicio}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  type="time"
                  label="Hora cierre"
                  name="hora_cierre"
                  value={formData.hora_cierre}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Disposición inicial MP"
                  name="disposicion_mp"
                  multiline
                  rows={4}
                  value={formData.disposicion_mp}
                  onChange={handleChange}
                />
              </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Límite embargabilidad"
              name="limite_embargabilidad"
              value={formData.limite_embargabilidad}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Número de resolución inicial"
              name="c_numero_resolucion"
              value={formData.c_numero_resolucion}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Número de citación inicial"
              name="c_numero_citacion"
              value={formData.c_numero_citacion}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="etapa_inicial"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl fullWidth margin="normal">
                  <InputLabel id="etapa-inicial-label">Seleccionar etapa inicial</InputLabel>
                  <Select
                    {...field}
                    labelId="etapa-inicial-label"
                    label="Seleccionar etapa inicial"
                    value={formData.etapa_inicial}
                    onChange={handleEtapaInicialChange}
                    // error={!!errors.notificacion}
                  >
                  <MenuItem value="">
                    <em>Sin Etapa inicial</em>
                  </MenuItem>
                    {etapas.map((etapa) => (
                      <MenuItem key={etapa.id} value={etapa.id}>
                        {etapa.etapa}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* {errors.notificacion && <p className="error-p">{errors.notificacion.message}</p>} */}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="etapa_final"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl fullWidth margin="normal">
                  <InputLabel id="etapa-final-label">Seleccionar etapa final</InputLabel>
                  <Select
                    {...field}
                    labelId="etapa-final-label"
                    label="Seleccionar etapa final"
                    value={formData.etapa_final}
                    onChange={handleEtapaFinalChange}
                    // error={!!errors.notificacion}
                  >
                  <MenuItem value="">
                    <em>Sin Etapa final</em>
                  </MenuItem>
                    {etapas.map((etapa) => (
                      <MenuItem key={etapa.id} value={etapa.id}>
                        {etapa.etapa}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* {errors.notificacion && <p className="error-p">{errors.notificacion.message}</p>} */}
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Box mt={2} display="flex" justifyContent="center">
          <Button type="submit" variant="contained" color="primary" sx={{background:'black'}}>
            Guardar
          </Button>
        </Box>
      </form>
    </Box> : ''}
      </Box>
  </Modal>
  );
};

export default ConfigGeneral;