import React, { useState } from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const Consulta = ({ data, setData, columns = [] }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedColumn, setSelectedColumn] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleColumnChange = (event) => {
    setSelectedColumn(event.target.value);
  };

  const handleSearchClick = () => {
    if (!selectedColumn) return;

    let searchValue = searchTerm;

    if (selectedColumn === 'tipo_notificacion') {
      if (searchTerm.toLowerCase() === 'correo') {
        searchValue = 1;
      } else if (searchTerm.toLowerCase() === 'personal') {
        searchValue = 0;
      }
    }

    const filteredData = data.filter((item) =>
      String(item[selectedColumn]).toLowerCase().includes(String(searchValue).toLowerCase())
    );
    setData(filteredData);
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <TextField
        fullWidth
        label="Buscar información"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <FormControl variant="outlined" style={{ minWidth: 120 }}>
        <InputLabel>Columna</InputLabel>
        <Select
          value={selectedColumn}
          onChange={handleColumnChange}
          label="Columna"
        >
          {columns.map((column, index) => (
            <MenuItem key={index} value={column}>
              {column}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSearchClick}
        sx={{background:'black'}}
      >
        <SearchIcon></SearchIcon>
      </Button>
    </Box>
  );
};

export default Consulta;
