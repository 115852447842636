import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import {styleModalDocs} from "../css/modal"

const EditarNotifModal = ({ open, handleClose, fetchData, notif }) => {
  const [formData, setFormData] = useState({
    notificacion: '',
    contenido: '',
    periodicidad: ''
  });

  useEffect(() => {
    if (notif) {
      setFormData({
        notificacion: notif.notificacion,
        contenido: notif.contenido,
        periodicidad: notif.periodicidad
      });
    }
  }, [notif]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData();
    data.append('notificacion', formData.notificacion);
    data.append('periodicidad', formData.periodicidad);
    data.append('contenido', formData.contenido);

    axios.put(`https://aleia.in/notificacion-modificar/${notif.id}`, data)
      .then(response => {
        fetchData();
        handleClose();
      })
      .catch(error => {
        console.error("There was an error updating the notification!", error);
      });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={styleModalDocs}>
        <Box mb={1} display="flex" justifyContent="center">
          <Typography variant="h5" component="h2">
            Editar Notificación
          </Typography>
        </Box>
        <TextField
          label="Nombre de la notificación"
          name="notificacion"
          value={formData.notificacion}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
        />
        <TextField
          label="Periodicidad de envío (días)"
          name="periodicidad"
          value={formData.periodicidad}
          onChange={handleChange}
          margin="normal"
          type="number"
          fullWidth
          required
        />
        <TextField
          label="Contenido del correo"
          name="contenido"
          value={formData.contenido}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
          multiline
          rows={13}
        />
        <Box mt={2} display="flex" justifyContent="center">
          <Button variant="contained" color="primary" onClick={handleSubmit} sx={{background:'black'}}>
            Guardar
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClose} sx={{ marginLeft: 2, background:'gray'}}>
            Cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditarNotifModal;
