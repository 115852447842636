import React from 'react';
import axios from 'axios';
import { Modal, Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate  } from "react-router-dom";
import {styleModalInmueble} from "../css/modal"

const InmuebleModal = ({ open, handleClose, inmueble  }) => {
  const handleOpenDocumento = () => {
    console.log('Abrir documento');
  };
  const navigate = useNavigate();

  const handleEnviarCorreo = async () => {
    const formData = new FormData();
    formData.append('id_inmueble', inmueble.id_inmueble);
    try {
      await axios.post('https://aleia.in/diligenciar-documento', formData);
      await axios.post('https://aleia.in/notificar', formData);
      navigate('/Registros');
    } catch (error) {
      console.error("Hubo un error al enviar el correo", error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={styleModalInmueble}>
        <Box mb={3} display="flex" justifyContent="center">
          <Typography variant="h5" component="h2">
            Detalles del cobro a realizar
          </Typography>
        </Box>
        
        {inmueble ? (
          <Grid container spacing={2}>
            <Grid item xs={6} textAlign="right">
              <Typography>ID Inmueble:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{inmueble.id_inmueble}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography>Nombre:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{inmueble.nombre}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography>Correo:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{inmueble.correo}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography>NID:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{inmueble.nid}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography>Teléfono:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{inmueble.telefono}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography>Predio:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{inmueble.predio}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography>Título:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{inmueble.titulo}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography>Liquidación:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{inmueble.liquidacion}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography>Vigencia:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{inmueble.vigencia}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography>Cuantía:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>${inmueble.cuantia}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography>Etapa:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                value={inmueble.nombre_etapa}
              >{inmueble.nombre_etapa}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography>Documento:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{inmueble.abreviacion_documento}</Typography>
            </Grid>
            <Grid item xs={4}>
            </Grid>

            <Box mt={3} display="flex" justifyContent="center" alignItems="center">
              <Button variant="contained" color="primary" onClick={handleEnviarCorreo} style={{ marginLeft: '15px' ,background:'black'}}>
                Enviar Correo
              </Button>
            </Box>
          </Grid>
        ) : (
          <Typography variant="h6" component="h4" textAlign="center">
            Cargando datos del inmueble...
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default InmuebleModal;
