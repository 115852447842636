import React, { useState, useEffect } from 'react';
import { Grid, Checkbox, Modal, Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import { styleModalDocs } from "../css/modal";
import { styled } from '@mui/system';

const EditarDocModal = ({ open, handleClose, fetchData, doc }) => {
  const [formData, setFormData] = useState({
    documento: '',
    formato: '',
    nombre: '',
    abreviacion: ''
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [multipleNot, setMultipleNot] = useState(false);

  useEffect(() => {
    if (doc) {
      setFormData({
        documento: doc.documento,
        formato: doc.formato,
        nombre: selectedFile,
        abreviacion: doc.abreviacion
      });
      setMultipleNot(doc.multiple === 1);
    }
  }, [doc, selectedFile]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleMultipleNot = (event) => {
    setMultipleNot(event.target.checked);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleFileButtonClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData();
    data.append('file', selectedFile);
    data.append('documento', formData.documento);
    data.append('abreviacion', formData.abreviacion);
    data.append('nombre', selectedFile ? selectedFile.name : '');
    data.append('multipleNot', multipleNot ? 1 : 0);

    axios.put(`https://aleia.in/documentos-modificar/${doc.id}`, data)
      .then(response => {
        fetchData();
        handleClose();
      })
      .catch(error => {
        console.error("There was an error updating the document!", error);
      });
  };

  const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    color: 'default',
    '&.Mui-checked': {
      color: '#30765e',
    },
    '& .MuiSvgIcon-root': {
      borderRadius: '50%',
    },
  }));

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={styleModalDocs}>
        <Box mb={1} display="flex" justifyContent="center">
          <Typography variant="h5" component="h2">
            Editar Documento
          </Typography>
        </Box>
        <TextField
          label="Nombre del documento"
          name="documento"
          value={formData.documento}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Siglas del documento"
          name="abreviacion"
          value={formData.abreviacion}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <input
          type="file"
          id="fileInput"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <Box mt={2} display="flex" justifyContent="left">
          <Button
            variant="contained"
            onClick={handleFileButtonClick}
            sx={{ background: 'gray', color: 'black' }}
          >
            Seleccionar archivo
          </Button>
          {selectedFile && (
            <Typography variant="body1" sx={{ marginLeft: 2 }}>
              {selectedFile.name}
            </Typography>
          )}
        </Box>
        <Grid container alignItems="center" spacing={2} mt={1} mb={2}>
          <Grid item>
            <Typography variant="h6">Notificación múltiple:</Typography>
          </Grid>
          <Grid item>
            <StyledCheckbox
              checked={multipleNot}
              onChange={handleMultipleNot}
            />
          </Grid>
        </Grid>
        <Box mt={3} display="flex" justifyContent="center">
          <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ background: 'black' }}>
            Guardar
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClose} sx={{ marginLeft: 2, background: 'gray' }}>
            Cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditarDocModal;
