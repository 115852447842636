import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Informe from "./main/general/Informe";
import Tabla_usuarios from "./usuarios/Tabla_usuarios";
import Tabla_registros from "./registros/Tabla_registros";
import Cobros_opciones from "./main/Cobros_opciones";
import MainResoluciones from "./main/Main_resoluciones";
import Resolucion from "./main/Gestion_resolucion";
import MainConfiguracion from "./main/Main_configuracion";
import GestionEtapas from "./etapas/Configuracion_etapas";
import GestionDocumentos from "./documentos/Tabla_documentos";
import GestionNotificaciones from "./notificaciones/Tabla_notificaciones";
import GestionRoles from './roles/Tabla_roles';
import Cobro_etapa from "./main/Cobro_etapa";
import ConfigGeneral from "./main/Config_general";
import Login from './login/Login';
import ProtectedRoute from './login/ProtectedRoute';
import UnauthorizedPage from './login/Unauthorized';

function App() {
  localStorage.setItem('currentPath', window.location.pathname);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route
        path="/Principal"
        element={
          <ProtectedRoute>
            <Informe />
          </ProtectedRoute>
        }
      />
      <Route
        path="/Registros"
        element={
          <ProtectedRoute>
            <Tabla_registros />
          </ProtectedRoute>
        }
      />
      <Route
        path="/Cobrosopc"
        element={
          <ProtectedRoute>
            <Cobros_opciones />
          </ProtectedRoute>
        }
      />
      <Route
        path="/Resoluciones"
        element={
          <ProtectedRoute>
            <MainResoluciones />
          </ProtectedRoute>
        }
      />
      <Route
        path="/Configuracion"
        element={
          <ProtectedRoute role='Administrador'>
            <MainConfiguracion />
          </ProtectedRoute>
        }
      />
      <Route
        path="/gestion-usuarios"
        element={
          // <ProtectedRoute>
            <Tabla_usuarios />
          // </ProtectedRoute>
        }
      />
      <Route
        path="/gestion-etapas"
        element={
          <ProtectedRoute>
            <GestionEtapas />
          </ProtectedRoute>
        }
      />
      <Route
        path="/config-general"
        element={
          <ProtectedRoute>
            <ConfigGeneral />
          </ProtectedRoute>
        }
      />
      <Route
        path="/documentos"
        element={
          <ProtectedRoute>
            <GestionDocumentos />
          </ProtectedRoute>
        }
      />
      <Route
        path="/notificaciones"
        element={
          <ProtectedRoute>
            <GestionNotificaciones />
          </ProtectedRoute>
        }
      />
      <Route
        path="/roles"
        element={
          <ProtectedRoute>
            <GestionRoles />
          </ProtectedRoute>
        }
      />
      {/* <Route
        path="/etapa/:id_etapa"
        element={
          <ProtectedRoute>
            <Cobro_etapa />
          </ProtectedRoute>
        }
      /> */}
      <Route
        path="/etapa"
        element={
          <ProtectedRoute>
            <Cobro_etapa />
          </ProtectedRoute>
        }
      />
      <Route
        path="/resolucion"
        element={
          <ProtectedRoute>
            <Resolucion />
          </ProtectedRoute>
        }
      />
      <Route
        path="/send-email"
      />
    </Routes>
  );
}

export default App;
