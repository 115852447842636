import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Typography, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { styleModalUser } from "../css/modal";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from '../js/validationSchema';

const CrearUsuarioModal = ({ open, handleClose, fetchData }) => {
  const { control, handleSubmit } = useForm();
  // const { control, handleSubmit, formState: { errors } } = useForm({
  //   resolver: yupResolver(validationSchema)
  // });
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    document.title = "Creación de usuario";

    const fetchRoles = async () => {
      const response = await axios.get('https://aleia.in/roles-listar');
      setRoles(response.data.rows);
    };

    fetchRoles();
  }, []);

  const onSubmit = async (data) => {
      await axios.post('https://aleia.in/registro', data)
      .then(response => {
        fetchData();
        handleClose();
      }).catch(error => {
        console.error("Hubo un error al crear el usuario", error);
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={styleModalUser}>
        <Box mb={1} display="flex" justifyContent="center">
          <Typography id="modal-title" variant="h5" component="h2">
            Crear Usuario
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="nombres"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Nombres"
                    // error={!!errors.nombres}
                    // helperText={errors.nombres ? errors.nombres.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="apellidos"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Apellidos"
                    // error={!!errors.apellidos}
                    // helperText={errors.apellidos ? errors.apellidos.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="correo"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Correo"
                    // error={!!errors.correo}
                    // helperText={errors.correo ? errors.correo.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="nid"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="NID"
                    // error={!!errors.nid}
                    // helperText={errors.nid ? errors.nid.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="telefono"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Teléfono"
                    // error={!!errors.telefono}
                    // helperText={errors.telefono ? errors.telefono.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="area"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Área"
                    // error={!!errors.area}
                    // helperText={errors.area ? errors.area.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="cargo"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Cargo"
                    // error={!!errors.cargo}
                    // helperText={errors.cargo ? errors.cargo.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="nombre_usuario"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Nombre de Usuario"
                    // error={!!errors.nombre_usuario}
                    // helperText={errors.nombre_usuario ? errors.nombre_usuario.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="roles"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormControl fullWidth margin='normal'>
                    <InputLabel id="roles-label">Seleccionar Rol</InputLabel>
                    <Select
                      {...field}
                      labelId="roles-label"
                      label="Roles"
                    >
                      {roles.map((rol) => (
                        <MenuItem key={rol.id} value={rol.id}>
                          {rol.rol}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="pwd"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Contraseña"
                    type="password"
                    // error={!!errors.pwd}
                    // helperText={errors.pwd ? errors.pwd.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Confirmar Contraseña"
                    type="password"
                    // error={!!errors.confirmPassword}
                    // helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box mt={2} display="flex" justifyContent="center">
            <Button type="submit" variant="contained" color="primary" sx={{ background: 'black' }}>
              Guardar
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default CrearUsuarioModal;
