import * as Yup from 'yup';

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const passRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=~`[\]{}|\\:;"'<>,.?/]).{8,20}$/;

export const validationSchema = Yup.object().shape({
  nombres: Yup.string()
    .matches(/^[A-Za-z]+$/, 'Los nombres solo pueden contener letras')
    .max(50, 'Los nombres solo pueden contener como máximo 50 carácteres')
    .required('El nombre es requerido'),
  apellidos: Yup.string()
    .matches(/^[A-Za-z]+$/, 'Los Apellidos solo pueden contener letras')
    .max(50, 'Los Apellidos solo pueden contener como máximo 50 carácteres')
    .required('Los Apellidos son requerido'),
  nombre_usuario: Yup.string()
    .matches(/^[A-Za-z0-9]+$/, 'El nombre de usuario solo puede contener letras y números')
    .min(6, 'El nombre de usuario debe contener como mínimo 6 carácteres')
    .max(20, 'El nombre de usuario solo puede contener como máximo 20 carácteres')
    .required('El nombre de usuario es requerido'),
  correo: Yup.string()
    .matches(emailRegex, 'Introduce un correo válido')
    .required('El correo es requerido'),
  nid: Yup.string()
    .matches(/^[0-9]+$/, 'El número de identificación solo puede contener números')
    .max(12, 'El número de identificación debe tener como máximo 12 dígitos')
    .required('El número de identificación es requerido'),
  telefono: Yup.string()
    .matches(/^[0-9]+$/, 'El teléfono solo puede contener números')
    .max(15, 'El teléfono debe tener como máximo 15 dígitos')
    .required('El teléfono es requerido'),
  area: Yup.string()
    .matches(/^[A-Za-z0-9]+$/, 'La dependencia solo puede contener letras y números')
    .max(50, 'La dependencia debe tener como máximo 50 dígitos')
    .required('La dependencia es requerida'),
  cargo: Yup.string()
    .matches(/^[A-Za-z0-9]+$/, 'El cargo solo puede contener letras y números')
    .max(20, 'El cargo debe tener como máximo 20 dígitos')
    .required('El cargo es requerido'),
  password: Yup.string()
    .matches(passRegex, 'La contraseña debe contener al menos una mayúscula, un número, un carácter especial, y debe ser de 8 a 20 caracteres')
    .required('La contraseña es requerida'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
    .required('La confirmación de la contraseña es requerida'),
  pwd: Yup.string()
    .matches(passRegex, 'La contraseña debe contener al menos una mayúscula, un número, un carácter especial, y debe ser de 8 a 20 caracteres')
    .required('La contraseña es requerida'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('pwd'), null], 'Las contraseñas deben coincidir')
    .required('La confirmación de la contraseña es requerida'),
});