import React from 'react';
import '../css/UnauthorizedPage.css';

const UnauthorizedPage = () => {
  return (
    <div className="unauthorized-container">
      <div className="unauthorized-content">
        <img src="/LOGO_ALEIA.png" alt="Unauthorized" className="unauthorized-image" />
        <p className="unauthorized-message">No tienes autorización para ingresar aquí</p>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
