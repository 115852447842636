import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {Table, TableBody, TableCell, TableSortLabel, TableContainer, TableHead, TableRow, Paper, IconButton, TablePagination, Box, Grid, TextField, Button, Tooltip } from '@mui/material';
import axios from 'axios';
import Main_sidebar from "./Main_sidebar";
import '../css/cobro_etapa.css';
import Consulta from './general/Consulta'
import LoadingHexagon from './general/LoadingHexagon';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';

const Resolucion = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [filteredRows, setFilteredRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [titulo, setTitulo] = useState([]);
  const [orderBy, setOrderBy] = useState('');
  const [orderDirection, setOrderDirection] = useState('asc');
  const [loading, setLoading] = useState(true); 
  const [inmuebleSel, setInmueble] = useState(null);
  const [file, setFile] = useState(null); 
  const [resolucion_id, setResolucionID] = useState(null);
  const [siglas, setSiglas] = useState([]);
  const [etapa_id, setEtapaID] = useState(null);
  const [nombreRes, setNombreRes] = useState(null);
  const resId = location.state?.resolucionId || localStorage.getItem('resolucionId');
  const etapaId = location.state?.etapaId || localStorage.getItem('etapaId');
  const excluir_col = ['id', 'id_etapa', 'id_res', 'id_doc', 'disposicion_inicial', 'nombre_res', 'nombre_completo', 'tipo_notificacion', 'etapa', 'duracion', 'multiple', 'abreviacion', 'created_at', 'updated_at', 'cuantia_letras'];
  const [varMultiple, setVarMultiple] = useState(0);
  const [configGen, setConfigGen] = useState(null);

  const [downloadedRows, setDownloadedRows] = useState([]);
  const [uploadedRows, setUploadedRows] = useState([]); 

  useEffect(() => {
    if(!resId) {
      navigate('/Cobrosopc');
    } else {
      localStorage.setItem('resolucionId', resId);
      setResolucionID(resId);
    }
    if (!etapaId) {
      navigate('/Cobrosopc');
    } else {
      localStorage.setItem('etapaId', etapaId);
      setEtapaID(etapaId);
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`https://aleia.in/etapa/${etapaId}`);
          const allColumns = response.data.columns || [];
          const allRows = response.data.rows || [];
          
          if (allRows.length === 0) {
            console.warn("No data found for the given resolucion.");
            setRows([]);
            setColumns([]);
            setFilteredRows([]);
          } else {
            setSiglas(allRows[0]?.abreviacion || '');
            // setDuracion(allRows[0]?.duracion || '');
            setVarMultiple(allRows[0]?.multiple || 0);
            setTitulo(allRows[0]['nombre_completo']);
            setNombreRes(allRows[0]['nombre_res']);
            setRows(allRows);
            setFilteredRows(allRows);
            const filtrarCols = allColumns.filter(column => !excluir_col.includes(column));
            setColumns(filtrarCols);
            // console.log("filteredRows:", filteredRows);
          }
        } catch (error) {
          console.error("There was an error fetching the data!", error);
        } finally {
          setLoading(false);
        }
      };
      const fetchCGData = async () => {
        try {
          const configGenRequest = await axios.get('https://aleia.in/get-config-gen');
          setConfigGen(configGenRequest.data);
        } catch (error) {
          console.error("Error fetching config general data", error);
        }
      };
      fetchData();
      fetchCGData();

    }
  }, [location, navigate]);
  
  useEffect(() => {
    document.title = "Gestión de "+ titulo;
  }, [titulo]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (column) => {
    const isAsc = orderBy === column && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(column);
    const sortedRows = [...filteredRows].sort((a, b) => {
      if (isAsc) {
        return a[column] < b[column] ? -1 : 1;
      } else {
        return a[column] > b[column] ? -1 : 1;
      }
    });
    setFilteredRows(sortedRows);
  };

  const handleDownload = async (record) => {
    try {
      // console.log('handleDownload: ',record);
      const form = new FormData();
      form.append('id_inmueble', record.inmueble);
      form.append('id_res', resId);
      const response = await axios.post('https://aleia.in/diligenciar-res', form);
      const fileUrl = response.data.file_url;
      
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadedRows((prev) => [...prev, record.inmueble]);
    } catch (error) {
      console.error('Error al descargar el documento:', error);
    } finally {
      navigate('/Resolucion');
    }
  };

  const handleUpload = async (record) => {
    try {
      if (!file) {
        alert('Por favor seleccione un archivo para subir.');
        return;
      }

      const form = new FormData();
      form.append('file', file);
      form.append('id_inmueble', record.inmueble);
      form.append('siglas', nombreRes);
      form.append('id_res', resolucion_id);
      form.append('nombre', titulo);

      const response = await axios.post('https://aleia.in/upload-acta', form, {
          headers: { 'Content-Type': 'multipart/form-data'}
      });

      if (response.status === 200) {
        alert('Archivo subido exitosamente.');
        setFile(null);
      }
    } catch (error) {
        console.error('Error al subir el documento:', error);
    } finally {
      navigate('/Resolucion');
    }
  };

  if (loading) {
    return <LoadingHexagon />;
  }

  return (
    <>
      <Main_sidebar />
      <Box className="config-container">
        <Grid item xs={12} textAlign="left" mb={0}>
          <h2>Gestión de resolucion para:</h2>
        </Grid>
        <Grid item xs={12} textAlign="left">
          <h1>{titulo}</h1>
        </Grid>
        <Grid container spacing={2} alignItems="center" mb={3}>
          <Grid item xs={8}>
            <Consulta data={rows} setData={setFilteredRows} columns={columns}/> 
          </Grid>
          {/* <Grid item xs={4} textAlign="right">
            <Button variant="contained" color="primary" onClick={handleOpenModal} sx={{background:'black'}}>
              Enviar notificación
            </Button>
          </Grid> */}
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer style={{ maxHeight: 700 }} component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell key={index} sortDirection={orderBy === column ? orderDirection : false}>

                        <TableSortLabel
                          active={orderBy === column}
                          direction={orderBy === column ? orderDirection : 'asc'}
                          onClick={() => handleSort(column)}
                        >
                          {column}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {columns.map((column, colIndex) => (
                        <TableCell key={colIndex}>
                          {column !== 'cuantia' &&  column !== 'fecha_oficio' ? row[column] : ''}
                          {column === 'cuantia' ? '$' + row[column] : ''}
                          {column === 'fecha_oficio' ? new Date(row[column]).toLocaleDateString('es-ES') : ''}
                        </TableCell>
                      ))}

                      <TableCell>
                        {/* Botón de Descargar */}
                        <IconButton 
                          onClick={() => handleDownload(row)}
                          style={{ color: downloadedRows.includes(row.inmueble) ? 'green' : 'default' }}
                        >
                          <DownloadIcon />
                        </IconButton>

                        {/* Input de archivo */}
                        <input
                          accept=".pdf"
                          style={{ display: 'none' }}
                          id={`upload-${row['inmueble']}`}
                          type="file"
                          onChange={(e) => {
                            setFile(e.target.files[0])
                            setUploadedRows((prev) => [...prev, row.inmueble])
                          }}
                          disabled={uploadedRows.includes(row.inmueble)}
                        />
                        
                        {/* Botón de Cargar/Subir */}
                        <label htmlFor={`upload-${row['inmueble']}`}>
                          <Button 
                            component="span"
                            disabled={uploadedRows.includes(row.inmueble)}  // Deshabilita si ya está cargado
                          >
                            {uploadedRows.includes(row.inmueble) ? 'Cargado' : 'Cargar PDF'}
                          </Button>
                        </label>

                        {/* Botón de Subida (sólo si el archivo ya está cargado) */}
                        {uploadedRows.includes(row.inmueble) && (
                          <IconButton onClick={() => handleUpload(row)}>
                            <UploadIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Box>
    </>
  );
};
export default Resolucion;