import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Typography, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from '../js/validationSchema';
import { styleModalUser } from "../css/modal";

const EditarUsuarioModal = ({ open, handleClose, fetchData, user }) => {
  const { control, handleSubmit, reset, watch } = useForm();
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const response = await axios.get('https://aleia.in/roles-listar');
      setRoles(response.data.rows);
    };

    if (user) {
      reset({
        nombres: user.nombres,
        apellidos: user.apellidos,
        correo: user.correo,
        nombre_usuario: user.nombre_usuario,
        nid: user.nid,
        telefono: user.telefono,
        area: user.area,
        cargo: user.cargo,
        pwd: '',
        roles: user.id_rol || ''
      });
    }

    fetchRoles();
  }, [user, reset]);

  const onSubmit = (data) => {
    const updatedData = { ...data };
    if (!data.pwd) {
      delete updatedData.pwd;
    }
    axios.put(`https://aleia.in/gestion-usuarios/modificar-usuario/${user.id}`, updatedData)
      .then(response => {
        console.log(response.data.message);
        fetchData();
        handleClose();
      })
      .catch(error => {
        console.error("There was an error updating the user!", error);
      });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={styleModalUser}>
        <Box mb={1} display="flex" justifyContent="center">
          <Typography id="modal-title" variant="h5" component="h2">
            Editar Usuario
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="nombres"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Nombres" fullWidth margin="normal" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="apellidos"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Apellidos" fullWidth margin="normal" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="correo"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Correo" fullWidth margin="normal" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="nid"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="NID" fullWidth margin="normal" />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="telefono"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Teléfono" fullWidth margin="normal" />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="area"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Área" fullWidth margin="normal" />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="cargo"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Cargo" fullWidth margin="normal" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="nombre_usuario"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Nombre de Usuario" fullWidth margin="normal" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="roles"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth margin='normal'>
                    <InputLabel id="roles-label">Seleccionar Rol</InputLabel>
                    <Select
                      {...field}
                      labelId="roles-label"
                      label="Roles"
                    >
                      {roles.map((rol) => (
                        <MenuItem key={rol.id} value={rol.id}>
                          {rol.rol}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="pwd"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Contraseña" type="password" fullWidth margin="normal" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Confirmar contraseña" type="password" fullWidth margin="normal" />
                )}
              />
            </Grid>
          </Grid>
          <Box mt={2} display="flex" justifyContent="center">
            <Button type="submit" variant="contained" color="primary" sx={{ background: 'black' }}>
              Guardar
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClose} sx={{ marginLeft: 2, background: 'gray' }}>
              Cancelar
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default EditarUsuarioModal;
