import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import {styleModalEtapas} from "../css/modal"

const EditarEtapaModal = ({ open, handleClose, fetchData, etapa }) => {
  const { control, handleSubmit, reset } = useForm();
  const [documentos, setDocumentos] = useState([]);
  const [notificaciones, setNotificaciones] = useState([]);
  const [etapas, setEtapas] = useState([]);
  const [resoluciones, setResoluciones] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [etapasRes, documentosRes, notificacionesRes, resolucionesRes] = await Promise.all([
          axios.get('https://aleia.in/gestion-etapas/listar-etapas'),
          axios.get('https://aleia.in/documentos-listar'),
          axios.get('https://aleia.in/notificaciones-listar'),
          axios.get('https://aleia.in/get-all-res'),
        ]);
  
        const etapasData = Array.isArray(etapasRes.data.rows) ? etapasRes.data.rows : [];
        const documentosData = Array.isArray(documentosRes.data.rows) ? documentosRes.data.rows : [];
        const notificacionesData = Array.isArray(notificacionesRes.data.rows) ? notificacionesRes.data.rows : [];
        const resolucionesData = Array.isArray(resolucionesRes.data.rows) ? resolucionesRes.data.rows : [];
  
        setEtapas(etapasData);
        setDocumentos(documentosData);
        setNotificaciones(notificacionesData);
        setResoluciones(resolucionesData);
        if (etapa) {
          reset({
            etapa: etapa.etapa,
            duracion: etapa.duracion,
            documento: documentosData.find(docu => docu.id === etapa.documento_i)?.id || '',
            notificacion: notificacionesData.find(notif => notif.id === etapa.notificacion_i)?.id || '',
            etapa_asociada: etapasData.find(e => e.id === etapa.etapa_asociada_i)?.id || '',
            etapa_siguiente: etapasData.find(e => e.id === etapa.etapa_siguiente_i)?.id || '',
            resolucion: resolucionesData.find(resol => resol.id === etapa.id_res)?.id || ''
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setEtapas([]);
        setDocumentos([]);
        setNotificaciones([]);
        setResoluciones([]);
      }
    };
  
    fetchData();
  }, [etapa, reset]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('etapa', data.etapa);
    formData.append('duracion', data.duracion);
    formData.append('etapa_asociada', data.etapa_asociada);
    formData.append('etapa_siguiente', data.etapa_siguiente);
    formData.append('documento', data.documento);
    formData.append('notificacion', data.notificacion);
    formData.append('resolucion', data.resolucion);

    axios.put(`https://aleia.in/gestion-etapas/etapa-modificar/${etapa.id}`, formData)
      .then(response => {
        fetchData();
        handleClose();
      })
      .catch(error => {
        console.error("Hubo un error al modificar la etapa", error);
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={styleModalEtapas}>
        <Box mb={1} display="flex" justifyContent="center">
          <Typography id="modal-title" variant="h5" component="h2">
            Editar Etapa
          </Typography>
        </Box>
        <form 
          onSubmit={handleSubmit(onSubmit)}
          method="POST"
          action="/etapa-modificar"
          encType="multipart/form-data">
          <Controller
            name="etapa"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                label="Nombre de la etapa"
              />
            )}
          />
          <Controller
            name="duracion"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                type="number"
                label="Duración (días)"
              />
            )}
          />
          <Controller
            name="etapa_asociada"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl fullWidth margin='normal'>
                <InputLabel id="etapa-aso-label">Seleccionar etapa asociada</InputLabel>
                <Select
                  {...field}
                  labelId="etapa-aso-label"
                  label="Seleccionar etapa asociada"
                >
                  <MenuItem value="">
                    <em>Ninguna</em>
                  </MenuItem>
                  {etapas.map((etapa) => (
                    <MenuItem key={etapa.id} value={etapa.id}>
                      {etapa.etapa}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="etapa_siguiente"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl fullWidth margin='normal'>
                <InputLabel id="etapa-sig-label">Seleccionar siguiente etapa</InputLabel>
                <Select
                  {...field}
                  labelId="etapa-sig-label"
                  label="Seleccionar siguiente etapa"
                  // error={!!error.etapa_asociada}
                >
                  <MenuItem value="">
                    <em>Ninguna</em>
                  </MenuItem>
                  {etapas.map((etapa) => (
                    <MenuItem key={etapa.id} value={etapa.id} >
                      {etapa.etapa}
                    </MenuItem>
                  ))}
                </Select>
                {/* {errors.etapa && <p className="error-etapa">{errors.etapa.message}</p>} */}
              </FormControl>
            )}
          />
          <Controller
            name="documento"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl fullWidth margin="normal">
                <InputLabel id="documento-label">Seleccionar documento</InputLabel>
                <Select
                  {...field}
                  labelId="documento-label"
                  label="Seleccionar documento"
                >
                  <MenuItem value="">
                    <em>No</em>
                  </MenuItem>
                  {documentos.map((doc) => (
                    <MenuItem key={doc.id} value={doc.id}>
                      {doc.documento}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="notificacion"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl fullWidth margin="normal">
                <InputLabel id="notificacion-label">Seleccionar notificación</InputLabel>
                <Select
                  {...field}
                  labelId="notificacion-label"
                  label="Seleccionar notificación"
                >
                  <MenuItem value="">
                    <em>Sin notificación</em>
                  </MenuItem>
                  {notificaciones.map((notif) => (
                    <MenuItem key={notif.id} value={notif.id}>
                      {notif.notificacion}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="resolucion"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl fullWidth margin="normal">
                <InputLabel id="resolucion-label">Seleccionar resolución</InputLabel>
                <Select
                  {...field}
                  labelId="resolucion-label"
                  label="Seleccionar resolución"
                  // error={!!errors.notificacion}
                >
                <MenuItem value="">
                  <em>Sin resolución</em>
                </MenuItem>
                  {resoluciones.map((resol) => (
                    <MenuItem key={resol.id} value={resol.id}>
                      {resol.nombre_completo}
                    </MenuItem>
                  ))}
                </Select>
                {/* {errors.notificacion && <p className="error-p">{errors.notificacion.message}</p>} */}
              </FormControl>
            )}
          />
          <Box mt={2} display="flex" justifyContent="center">
            <Button type="submit" variant="contained" color="primary" sx={{background:'black'}}>
              Guardar
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClose} sx={{ marginLeft: 2, background:'gray'}}>
              Cancelar
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default EditarEtapaModal;
