
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, CardContent, Typography, Grid, Box, Snackbar } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Main_sidebar from "./Main_sidebar";
import '../css/main_sidebar.css';
import LoadingHexagon from '../main/general/LoadingHexagon';
import ArticleIcon from '@mui/icons-material/Article';

export default function Main_registros() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); 
    const [etapas, setEtapas] = useState([]);
    const [inmueblesEtapaData, setInmueblesEtapaData] = useState([]);
    const [documentos, setDocumentos] = useState([]);
    const [notificadosData, setNotificadosData] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        document.title = "Gestión de Cobros Coactivos";
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get('https://aleia.in/gestion-etapas/listar-etapas');
                const etapasData = response.data.rows || [];
                setEtapas(etapasData);
            } catch (error) {
                console.error("There was an error fetching the data!", error);
            }finally {
                setLoading(false);
              }
        };

        const fetchInmueblesEtapaData = async () => {
            try {
                const response = await axios.get('https://aleia.in/get-inmueble-etapa');
                const response2 = await axios.get('https://aleia.in/documentos-listar');
                
                setDocumentos(response2.data.rows);
                setInmueblesEtapaData(response.data.rows);
            } catch (error) {
                console.error("Error fetching inmuebles etapa data", error);
            }
        };

        const fetchNotificadosData = async () => {
            try {
                const response = await axios.get('https://aleia.in/get-notificados');
                setNotificadosData(response.data.rows);
            } catch (error) {
                console.error("Error fetching notificados data", error);
            }
        };
        fetchData();
        fetchInmueblesEtapaData();
        fetchNotificadosData();
    }, []);

    const options = {
          icon: <ArticleIcon style={{ fontSize: 40 }} />
        };
    

    const handleMouseEnter = (etapa) => {
        if (['Resolución Recurso de Reposición', 'Resolución Escrito de Excepciones', 'Resolución que Ordena Seguir Adelante'].includes(etapa)) {
            setSnackbarMessage('Dicha opción se debe gestionar en la opción de Resoluciones');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    if (loading) {
        return <LoadingHexagon />;
    }

    return (
        <>
            <Main_sidebar />
            <Box className="config-container" alignItems="center">
                <Box display="flex" mt={2} sx={{ textAlign: 'center', marginBottom: '20px' }}>
                    <Typography variant="h4" component="div" gutterBottom>
                        Etapas de cobro coactivo
                    </Typography>
                </Box>
                <Grid container spacing={3} textAlign='left' justifyContent="center" alignItems="center">
                    {etapas.map((etapa) => {
                        const documento = documentos.find(docs => docs.id === etapa.documento)?.documento || 'Sin documento';
                        const isRestrictedEtapa = ['Resolución Recurso de Reposición', 'Resolución Escrito de Excepciones', 'Resolución que Ordena Seguir Adelante'].includes(etapa.etapa);

                        const notificados = inmueblesEtapaData.filter(inmueble =>
                            notificadosData.some(notificado => notificado.inmueble === inmueble.inmueble && notificado.id_etapa === etapa.id)
                        );

                        const pendientes = inmueblesEtapaData.filter(inmuebles => 
                            !notificadosData.some(notificado => notificado.inmueble === inmuebles.inmueble && notificado.id_etapa === etapa.id) &&

                            inmuebles.etapa === etapa.id).length


                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={etapa.id}>
                                <Card
                                    onClick={!isRestrictedEtapa ? () => {
                                        localStorage.setItem('etapaId', etapa.id);
                                        navigate(`/Etapa`, { state: { etapaId: etapa.id , resolucionId: etapa.id_res} });
                                    } : ()=>{
                                        localStorage.setItem('resolucionId', etapa.id_res);
                                        navigate(`/Resolucion`, { state: { etapaId: etapa.id , resolucionId: etapa.id_res } });
                                    }}>
                                    <CardContent>
                                        <ArticleIcon style={{ fontSize: 40, textAlign: 'center' }} />
                                        <Typography variant="h5" component="div">
                                            {etapa.etapa}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Duración: {etapa.duracion} días
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Documento: {documento}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Notificados: {notificados.length}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Pendientes: {pendientes}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={1500}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </>
    );
}
