import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, Box} from '@mui/material';
import axios from 'axios';
import ArticleIcon from '@mui/icons-material/Article';
import Main_sidebar from "./Main_sidebar";
import "../css/main_configuracion.css";

const MainResoluciones = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [resolutions, setResolutions] = useState([]); 

  const navigate = useNavigate();
  
  useEffect(() => {
    document.title = "Resoluciones";
  }, []);
  useEffect(() => {
    axios.get('https://aleia.in/get-all-res')
        .then(response => {
            if (response.data.rows && Array.isArray(response.data.rows)) {
                setResolutions(response.data.rows); 
            } else {
                setResolutions([]);
            }
        })
        .catch(error => {
            console.error('Error fetching resolutions:', error);
        });
        
  }, []);

  const handleCardClick = (index, path) => {
    setOpenIndex(openIndex === index ? null : index);
    if (path) {
      navigate(path);
    }
  };
  if (resolutions){
    const options = [
      {
        title: 'R. Recurso de Reposición', 
        path: `/resolucion/${1}`,
        icon: <ArticleIcon style={{ fontSize: 40 }} />
      },
      { title: 'R. Escrito de Excepciones',
         path: `/resolucion/${2}`, 
         icon: <ArticleIcon style={{ fontSize: 40 }} /> },
      // { title: 'R. de Embargos', icon: <ArticleIcon style={{ fontSize: 40 }} />
      //   , path: `/resolucion/${3}` 
      // },
      {
        title: 'R. que Ordena Seguir Adelante con la Ejecución',
         path: `/resolucion/${4}`,
        icon: <ArticleIcon style={{ fontSize: 40 }} />
      },
    ];
    return (
      <>
        <Main_sidebar />
        <Box className="config-container">
          <Typography variant="h3" component="div" className="config-header" mb={6} mt={4}>
              Resoluciones
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {options.map((option, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <Card
                  className="card-conf"
                  onClick={() => handleCardClick(index, option.path)}
                  style={{ textAlign: 'center', padding: '20px' }}
                >
                  <CardContent>
                    {option.icon}
                    <Typography variant="h6" component="div" style={{ marginTop: '10px' }}>
                      {option.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </>
    );

  };

};

export default MainResoluciones;
