import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import {styleModalNotif} from "../css/modal"
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from '../js/validationSchema';
import { useNavigate } from 'react-router-dom';

const CrearNotifModal = ({ open, handleClose, fetchData }) => {
  const { control, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('notificacion', data.notificacion);
    formData.append('contenido', data.contenido);
    formData.append('periodicidad', data.periodicidad);

    axios.post('https://aleia.in/notificacion-crear', formData)
        .then(response => {
            fetchData();
            handleClose();
        })
        .catch(error => {
            console.error("Hubo un error al agregar el documento", error);
        });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={styleModalNotif}>
        <Box mb={1} display="flex" justifyContent="center">
          <Typography id="modal-title" variant="h5" component="h2">
            Crear Notificación
          </Typography>
        </Box>
        <form 
          onSubmit={handleSubmit(onSubmit)}
          method="POST"
          action="/notificacion-crear"
          enctype="multipart/form-data">
          <Controller
            name="notificacion"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                label="Nombre de la notificación"
                // error={!!errors.notificacion}
                // helperText={errors.notificacion ? errors.notificacion.message : ''}
              />
            )}
          />
          <Controller
            name="periodicidad"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                type="number"
                label="Periodicidad de envío (días)"
                // error={!!errors.periodicidad}
                // helperText={errors.periodicidad ? errors.periodicidad.message : ''}
              />
            )}
          />
         <Controller
            name="contenido"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                multiline
                rows={13}
                label="Contenido del correo"
                // error={!!errors.contenido}
                // helperText={errors.contenido ? errors.contenido.message : ''}
              />
            )}
          />
          <Box mt={2} display="flex" justifyContent="center">
            <Button type="submit"  variant="contained" sx={{background:'black'}}>
              Guardar
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default CrearNotifModal;
