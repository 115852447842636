import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import {styleModalRol} from "../css/modal"

const EditarRolModal = ({ open, handleClose, fetchData, rolu }) => {
  const [formData, setFormData] = useState({
    rol: '',
    permisos: ''
  });

  useEffect(() => {
    if (rolu) {
      setFormData({
        rol: rolu.rol,
        permisos: rolu.permisos
      });
    }
  }, [rolu]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData();
    data.append('rol', formData.rol);
    data.append('permisos', formData.permisos);

    axios.put(`https://aleia.in/rol-modificar/${rolu.id}`, data)
      .then(response => {
        fetchData();
        handleClose();
      })
      .catch(error => {
        console.error("There was an error updating the role!", error);
      });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={styleModalRol}>
        <Box mb={1} display="flex" justifyContent="center">
          <Typography variant="h5" component="h2">
            Editar Rol
          </Typography>
        </Box>
        <TextField
          label="Nombre del rol"
          name="rol"
          value={formData.rol}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
        />
        <TextField
          label="Permisos"
          name="permisos"
          value={formData.permisos}
          onChange={handleChange}
          margin="normal"
          fullWidth
          required
        />
        <Box mt={2} display="flex" justifyContent="center">
          <Button variant="contained" color="primary" onClick={handleSubmit} sx={{background:'black'}}>
            Guardar
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClose} sx={{ marginLeft: 2, background:'gray'}}>
            Cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditarRolModal;
