import React from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';
import {styleModalDel} from "../css/modal"

const ConfirmDeleteModal = ({ open, handleClose, handleConfirm }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={styleModalDel}>
        <Box mb={1} display="flex" justifyContent="center">
          <Typography variant="h6" component="h2">
            Confirmar eliminación
          </Typography>
        </Box>
        <Typography mb={2}>¿Estás seguro de que deseas eliminar este usuario?</Typography>
        <Box display="flex" justifyContent="center">
          <Button variant="contained" color="primary" onClick={handleConfirm} sx={{background:'black'}}>
            Sí
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClose} sx={{ marginLeft: 2, background:'gray'}}>
            No
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmDeleteModal;
