import React, { useState, useContext } from "react";
import '../css/main_sidebar.css';
import { useNavigate } from "react-router-dom";
import ActaNotificacion from './ActaNotificacion';
import { AuthContext } from '../login/AuthContext';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';

export default function Main_sidebar() {
    const [opciones, setOpciones] = useState(true);

    function handleClick() {
        setOpciones(!opciones);
    }

    return (
        <div className="container">
            <div className={opciones ? "sidebar" : "sidebar-short"} id="sidebar">
                <div className="menu-header">
                    <div className="menu-icon" id="menu-icon" onClick={handleClick}>
                        &#9776;
                    </div>
                    {opciones && <div className="label">Aleia</div>}
                </div>
                <div className="spacer"></div>
                {opciones ? <Opciones_main /> : ""}
            </div>
        </div>
    );
}

function Opciones_main() {
    const [openActa, setOpenActa] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const navigate = useNavigate();
    const { logout } = useContext(AuthContext);

    const handleNavigationEA = (event) => {
        event.preventDefault();
        navigate('/Principal');
    };

    const handleNavigation = (event) => {
        event.preventDefault();
        navigate('/Registros');
    };

    const handleNavigationCobros = (event) => {
        event.preventDefault();
        navigate('/Cobrosopc');
    };

    const handleOpenActa = () => {
        setOpenActa(true);
    };

    const handleNavigationConfig = (event) => {
        event.preventDefault();
        navigate('/Configuracion');
    };

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const CustomDialogTitle = styled(DialogTitle)({
        textAlign: 'center',
    });

    const CustomDialogContent = styled(DialogContent)({
        textAlign: 'center',
    });

    const CustomDialogActions = styled(DialogActions)({
        justifyContent: 'center',
    });

    const customButtonStyles = {
        borderRadius: '5px',
        textTransform: 'none',
    };

    return (
        <div className="sidebar-opcs">
            <a href="/EstadoActual" onClick={handleNavigationEA}>Estado actual</a>
            <a href="/Registros" onClick={handleNavigation}>Gestión de registros</a>
            <a href="/Cobrosopc" onClick={handleNavigationCobros}>Gestión de cobros coactivos</a>
            <a onClick={handleOpenActa}>Acta de notificación</a>
            <a href="/Configuracion" onClick={handleNavigationConfig}>Configuración</a>
            <a href="#" onClick={handleOpenConfirm}>Salir</a> 
            <ActaNotificacion open={openActa} handleClose={() => setOpenActa(false)} />
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="confirm-logout-dialog"
                PaperProps={{
                    sx: {
                        borderRadius: '10px', 
                    }
                }}
            >
                <CustomDialogTitle id="confirm-logout-dialog">
                    Confirmar Cierre de Sesión
                </CustomDialogTitle>
                <CustomDialogContent>
                    <DialogContentText>
                        ¿Estás seguro de que deseas cerrar sesión?
                    </DialogContentText>
                </CustomDialogContent>
                <CustomDialogActions>
                    <Button 
                        onClick={handleCloseConfirm} 
                        sx={{ ...customButtonStyles, color: 'grey' }} 
                    >
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleLogout} 
                        sx={{ ...customButtonStyles, color: 'black' }} 
                    >
                        Salir
                    </Button>
                </CustomDialogActions>
            </Dialog>
        </div>
    );
}
