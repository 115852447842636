import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userRole, setUserRole] = useState(null);  
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            try {
                const decodedToken = jwt_decode(token);
                setIsAuthenticated(true);
                setUserRole(decodedToken.role); 
            } catch (error) {
                console.error('Error decoding token:', error);
                setIsAuthenticated(false);
                setUserRole(null);
            }
        } else {
            setIsAuthenticated(false);
            setUserRole(null);
        }
    }, [navigate]);

    useEffect(() => {
        if (isAuthenticated && window.location.pathname === '/') {
            navigate('/Principal');
        } else if (!isAuthenticated && window.location.pathname !== '/') {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    const login = (token) => {
        if (token) {
            const decodedToken = jwt_decode(token);
            setIsAuthenticated(true);
            setUserRole(decodedToken.role); 
            localStorage.setItem('authToken', token);
            // localStorage.setItem('currentPath', '/Principal');
            const savedPath = localStorage.getItem('currentPath') ||  '/Principal';
            navigate(savedPath);
        }
    };
    const logout = () => {
        setIsAuthenticated(false);
        setUserRole(null); 
        localStorage.removeItem('authToken');
        localStorage.removeItem('currentPath');
        navigate('/');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, userRole, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
