import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Grid, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import axios from 'axios';
import { styleModalUser } from "../css/modal";

const ActaNotificacion = ({ open, handleClose }) => {
    const [selectedField, setSelectedField] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [file, setFile] = useState(null); 
    
    const handleFieldChange = (event) => {
        setSelectedField(event.target.value);
    };

    const handleInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const handleSearch = async () => {
        if (selectedField && searchInput) {
            try {
                const response = await axios.get(`https://aleia.in/search-to-acta`, {
                    params: {
                        field: selectedField,
                        query: searchInput
                    }
                });
                // console.log('Resultado: ', response.data.results);
                setSearchResults(response.data.results);
            } catch (error) {
                console.error('Error al realizar la consulta:', error);
            }
        }
    };

    const handlePrint = async (record) => {
        try {
            const form = new FormData();
            form.append('id_inmueble', record.id_inmueble);
            const response = await axios.post('https://aleia.in/diligenciar-acta', form);
            const fileUrl = response.data.file_url;
        
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error al descargar el documento:', error);
        }
    };

    // Nueva función para manejar la subida de archivos
    const handleUpload = async (record) => {
        try {
            if (!file) {
                alert('Por favor seleccione un archivo para subir.');
                return;
            }

            const form = new FormData();
            form.append('file', file);
            form.append('id_inmueble', record.id_inmueble); 
            form.append('siglas', 'AN'); 

            const response = await axios.post('https://aleia.in/upload-acta', form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                alert('Archivo subido exitosamente.');
                setFile(null); // Reseteamos el estado del archivo
            }
        } catch (error) {
            console.error('Error al subir el documento:', error);
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={styleModalUser}>
                <Typography align='center' variant="h5" component="div" className="config-header" mb={2}>
                    Acta de Notificación
                </Typography>
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            select
                            label="Seleccionar campo"
                            value={selectedField}
                            onChange={handleFieldChange}
                            fullWidth
                        >
                            <MenuItem value="nombre">Nombre</MenuItem>
                            <MenuItem value="id_inmueble">ID Inmueble</MenuItem>
                            <MenuItem value="nid">Número de Identificación</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Buscar"
                            value={searchInput}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSearch}
                            startIcon={<SearchIcon />}
                            fullWidth
                        >
                            Buscar
                        </Button>
                    </Grid>
                </Grid>
                {searchResults.length > 0 ? (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>ID Inmueble</TableCell>
                                    <TableCell>Número de Identificación</TableCell>
                                    <TableCell>Vigencia</TableCell>
                                    <TableCell>Cuantía</TableCell>
                                    <TableCell>Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {searchResults.map((result, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{result.nombre}</TableCell>
                                        <TableCell>{result.id_inmueble}</TableCell>
                                        <TableCell>{result.nid}</TableCell>
                                        <TableCell>{result.vigencia}</TableCell>
                                        <TableCell>{result.cuantia}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handlePrint(result)}>
                                                <DownloadIcon />
                                            </IconButton>
                                            <input
                                                accept=".pdf"
                                                style={{ display: 'none' }}
                                                id={`upload-${result.id_inmueble}`}
                                                type="file"
                                                onChange={(e) => setFile(e.target.files[0])}
                                            />
                                            <label htmlFor={`upload-${result.id_inmueble}`}>
                                                <Button component="span">
                                                    Subir Acta
                                                </Button>
                                            </label>
                                            <IconButton onClick={() => handleUpload(result)}>
                                                <UploadIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : ''}
            </Box>
        </Modal>
    );
};

export default ActaNotificacion;
