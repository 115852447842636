import React, { useEffect, useState } from 'react';
import { Modal, Box, TextField, Button, Typography, FormControl, InputLabel, Select, MenuItem, Checkbox, Grid } from '@mui/material';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import {styleModalR} from "../css/modal"
import { styled } from '@mui/system';

const EditarRegModal = ({ open, handleClose, fetchData, registro }) => {
  const { control, handleSubmit, watch, setValue, reset } = useForm();
  const [etapas, setEtapas] = useState([]);
  const [anios, setAnios] = useState([]);
  const [tipoNot, SetTipoNot] = useState(false);

  const handleTipoNot = (event) => {
    SetTipoNot(event.target.checked);
  };
  const values = [];
  useEffect(() => {
    const fetchAnios = async () => {
      try {
        const response = await axios.get('https://aleia.in/anios-listar');
        setAnios(Array.isArray(response.data.rows) ? response.data.rows : []);
      } catch (error) {
        console.error('Error fetching anios:', error);
        setAnios([]);
      }
    };
    const fetchEtapas = async () => {
      try {
        const response = await axios.get('https://aleia.in/gestion-etapas/listar-etapas');
        setEtapas(Array.isArray(response.data.rows) ? response.data.rows : []);
      } catch (error) {
        console.error('Error fetching etapas:', error);
        setEtapas([]);
      }
    };
    fetchEtapas();
    fetchAnios();

    if (registro) {
      if (registro.fecha_titulo && registro.fecha_liquidacion) {
        values.fecha_liquidacion = new Date(registro.fecha_liquidacion).toISOString().split('T')[0];
        values.fecha_titulo = new Date(registro.fecha_titulo).toISOString().split('T')[0];
        values.nombre_etapa = registro.nombre_etapa;
      }
      SetTipoNot(registro.tipo_notificacion === 1);

      reset({
        id_inmueble: registro.id_inmueble,
        nombre: registro.nombre,
        correo: registro.correo,
        nid: registro.nid,
        direccion_notificacion: registro.direccion_notificacion,
        tipo_notificacion: tipoNot,
        telefono: registro.telefono,
        predio: registro.predio,
        tipo_predio: registro.tipo_predio,
        uso_predio: registro.uso_predio,
        direccion_predio: registro.direccion_predio,
        matricula: registro.matricula,
        titulo: registro.titulo,
        fecha_titulo: values.fecha_titulo,
        liquidacion: registro.liquidacion,
        fecha_liquidacion: values.fecha_liquidacion,
        vigencia: registro.vigencia,
        cuantia: registro.cuantia,
        cuantia_letras: registro.cuantia_letras,
        etapa: etapas.find(etapa => etapa.etapa === values.nombre_etapa)?.id || ''

      });
    }

  }, [registro, reset]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('nombre', data.nombre);
    formData.append('correo', data.correo);
    formData.append('nid', data.nid);
    formData.append('direccion_notificacion', data.direccion_notificacion);
    formData.append('tipo_notificacion', tipoNot ? 1 : 0);
    formData.append('telefono', data.telefono);
    formData.append('predio', data.predio);
    formData.append('direccion_predio', data.direccion_predio);
    formData.append('tipo_predio', data.tipo_predio);
    formData.append('uso_predio', data.uso_predio);
    formData.append('matricula', data.matricula);
    formData.append('titulo', data.titulo);
    formData.append('fecha_titulo', data.fecha_titulo);
    formData.append('liquidacion', data.liquidacion);
    formData.append('fecha_liquidacion', data.fecha_liquidacion);
    formData.append('vigencia', data.vigencia);
    formData.append('cuantia', data.cuantia);
    
    formData.append('etapa', data.etapa);
    console.log(formData);
    try {
      await axios.put(`https://aleia.in/registro-modificar/${registro.id}`, formData);
      fetchData();
      handleClose();
    } catch (error) {
      console.error("Hubo un error al crear/modificar el registro del inmueble", error);
    }
  };

  const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    color: 'default',
    '&.Mui-checked': {
      color: '#30765e',
    },
    '& .MuiSvgIcon-root': {
      borderRadius: '50%',
    },
  }));

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={styleModalR}>
        <Box mb={1} display="flex" justifyContent="center">
          <Typography id="modal-title" variant="h5" component="h2">
            Editar Registro de Inmueble
          </Typography>
        </Box>
        <form 
          onSubmit={handleSubmit(onSubmit)}
          method='POST'
          action='/registro-modificar'
          encType="multipart/form-data"
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="nombre"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Nombre(s) y apellido(s)"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="nid"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Número de identificación"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="direccion_notificacion"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Dirección de notificación"
                  />
                )}
              />
            </Grid>
            <Grid container alignItems="center" item xs={4} spacing={2} mt={1} mb={2}>
              <Grid item>
                <Typography variant="h6">Notificación por correo:</Typography>
              </Grid>
              <Grid item>
                <StyledCheckbox checked={tipoNot} onChange={handleTipoNot} />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="telefono"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Teléfono"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="correo"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Correo"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="predio"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Predio"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="direccion_predio"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Dirección de predio"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="tipo_predio"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Tipo de predio"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="uso_predio"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Uso de predio"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="matricula"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Número de matrícula"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="titulo"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Título"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="fecha_titulo"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    type="date"
                    label="Fecha del título"
                    value={values.fecha_titulo}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="liquidacion"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Liquidación"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="fecha_liquidacion"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    type="date"
                    label="Fecha de liquidación"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="vigencia"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormControl fullWidth margin='normal'>
                    <InputLabel id="vigencia-label">Seleccionar año de vigencia</InputLabel>
                    <Select
                      {...field}
                      labelId="vigencia-label"
                      label="Vigencia"
                    >
                      {anios.map((anio) => (
                        <MenuItem key={anio.id} value={anio.anio}>
                          {anio.anio}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="cuantia"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Cuantía"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} hidden>
              <Controller
                name="cuantia_letras"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Cuantía en Letras"
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="etapa"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormControl fullWidth margin='normal'>
                    <InputLabel id="etapa-label">Seleccionar etapa</InputLabel>
                    <Select
                      {...field}
                      labelId="etapa-label"
                      label="Seleccionar etapa"
                    >
                      {etapas.map((etapa) => (
                        <MenuItem key={etapa.id} value={etapa.id}>
                          {etapa.etapa}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          <Box mt={2} display="flex" justifyContent="center">
            <Button type="submit" variant="contained" color="primary" sx={{background:'black'}}>
              Guardar
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClose} sx={{ marginLeft: 1 ,background:'gray'}}>
              Cancelar
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default EditarRegModal;
