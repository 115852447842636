import React, { useState, useEffect } from 'react';
import { Grid, Modal, Box, Checkbox, Button, Typography } from '@mui/material';
import axios from 'axios';
import {styleModalAlertas} from "../css/modal"
import { styled } from '@mui/system';

const GestionModal = ({ open, handleClose }) => {
  const [alertasGst, setAlertasGst] = useState([]);

  const fetchAlertasGst = async () => {
    try {
      const response = await axios.get('https://aleia.in/get-gst-alertas');
      const allRows = response.data.rows || [];
      if (allRows.length === 0) {
          console.warn("No data de gestión alertas.");
          setAlertasGst([]);
      } else {
          setAlertasGst(allRows);
      }
    } catch (error) {
      console.error('Error fetching Alertas:', error);
      setAlertasGst([]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
 
    const estados = alertasGst.map(alerta => ({
      id: alerta.id,
      estado: alerta.estado
    }));
  
    try {
      const response = await axios.post('https://aleia.in/alertas-gst-modificar', { estados });
      if (response.status === 200) {
          handleClose('Se han registrados los cambios de las alertas por correo');
        }
    } catch (error) {
      console.error('Error al guardar las alertas:', error);
      handleClose('Error al registrar los cambios');
    }
  };

  const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    color: 'default',
    '&.Mui-checked': {
      color: '#30765e',
    },
    '& .MuiSvgIcon-root': {
      borderRadius: '50%',
    },
  }));

  useEffect(() => {
    if (open) {
      fetchAlertasGst();
    }
  }, [open]);

  return (
    <Modal open={open} onClose={() => handleClose('')}>
      <Box sx={styleModalAlertas}>
        <Typography align='center' variant="h5" component="div" className="config-header" mb={2}>
          Gestión de alertas
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Estas son las alertas por correo disponibles:</Typography>
            </Grid>
            {alertasGst.map((row, rowIndex) => (
              <Grid container key={rowIndex} alignItems="center" spacing={2} margin={1}>
                <Grid item xs={9}>
                  <Typography variant="h6">{row.nombre}:</Typography>
                  <Typography variant="body2">{row.descripcion}</Typography>
                </Grid>
                <Grid item xs={3} display="flex" alignItems="center">
                  <StyledCheckbox
                    checked={row.estado}
                    onChange={(e) => {
                      const updatedAlertas = [...alertasGst];
                      updatedAlertas[rowIndex].estado = e.target.checked ? 1 : 0;
                      setAlertasGst(updatedAlertas);
                    }}
                  />
                  <Typography variant="body2">
                    {row.estado ? 'Activo' : 'Inactivo'}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Box mt={3} display="flex" justifyContent="center">
            <Button type="submit" variant="contained" color="primary" sx={{background:'black'}}>
              Guardar
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default GestionModal;
