import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, Box, Collapse, List, ListItem, ListItemText, Snackbar } from '@mui/material';
import axios from 'axios';
import PaymentIcon from '@mui/icons-material/Payment';
import SecurityIcon from '@mui/icons-material/Security';
import GroupIcon from '@mui/icons-material/Group';
import ArticleIcon from '@mui/icons-material/Article';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Main_sidebar from "./Main_sidebar";
import ConfigGeneral from './Config_general';
import ResR3 from '../resoluciones/Modal_R3';
import ResREE from '../resoluciones/Modal_REE';
import ResRE from '../resoluciones/Modal_RE';
import ResROSAE from '../resoluciones/Modal_ROSAE';
import DatasetModal from './Modal_dataset'; 
import GestionModal from '../Alertas/Gestion_alertas'; 
import "../css/main_configuracion.css";

const MainConfiguracion = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openR3, setOpenR3] = useState(null);
  const [openREE, setOpenREE] = useState(null);
  const [openRE, setOpenRE] = useState(null);
  const [openROSAE, setOpenROSAE] = useState(null);
  const [openDatasetModal, setOpenDatasetModal] = useState(false); 
  const [openAlertasModal, setOpenAlertasModal] = useState(false); 
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();
  
  useEffect(() => {
    document.title = "Configuración";
  }, []);

  const handleCardClick = (index, path, isModal) => {
    setOpenIndex(openIndex === index ? null : index);
    if (path) {
      navigate(path);
    } else if (isModal) {
      if (index === 4) {
        setOpenModal(true);
      } else if (index === 5) {
        setOpenDatasetModal(true);
      } 
    }
  };

  const handleSubOptionClick = (path, isModal, subIndex, tipo=null) => {
    if (path) {
      navigate(path);
    } else if (isModal) {
      if (subIndex === 0) {
        setOpenR3(true);
      } else if (subIndex === 1 && tipo === null) {
        setOpenREE(true);
      } else if (subIndex === 2 && tipo === null) {
        setOpenRE(true);
      } else if (subIndex === 3 && tipo === null) {
        setOpenROSAE(true);
      } else if (tipo === 'gestion_cobro') {
        setOpenAlertasModal(true);
      }
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Manejo de la Snackbar
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // Función que se pasa al GestionModal para mostrar la Snackbar al cerrar
  const handleCloseGestionModal = (message) => {
    setOpenAlertasModal(false);
    if (message) {
      setSnackbarMessage(message);
      setOpenSnackbar(true);
    }
  };

  const options = [
    {
      title: 'Configurar gestión de cobro',
      icon: <PaymentIcon style={{ fontSize: 40 }} />,
      subOptions: [
        { title: 'Gestión de etapas', path: '/gestion-etapas' },
        { title: 'Formatos y documentación', path: '/documentos' },
        { title: 'Notificaciones', path: '/notificaciones' },
        { title: 'Alertas', isModal: true, tipo: 'gestion_cobro'}
      ]
    },
    { title: 'Roles y permisos', path: '/roles', icon: <SecurityIcon style={{ fontSize: 40 }} /> },
    { title: 'Gestión de usuarios', icon: <GroupIcon style={{ fontSize: 40 }} />, path: '/gestion-usuarios' },
    {
      title: 'Gestión de resoluciones',
      icon: <ArticleIcon style={{ fontSize: 40 }} />,
      subOptions: [
        { title: 'Resolución de Recurso de Reposicion (R3)', isModal: true },
        { title: 'Resolución de Escrito de Excepciones (REE)', isModal: true },
        { title: 'Resolución de Embargos (RE)', isModal: true },
        { title: 'Resolución que Ordena Seguir Adelante con la Ejecución (ROSAE)', isModal: true }
      ]
    },
    { title: 'Parámetros generales', icon: <ChromeReaderModeIcon style={{ fontSize: 40 }} />, isModal: true },
    { title: 'Agregar conjunto de datos', icon: <AddCircleIcon style={{ fontSize: 40 }} />, isModal: true } // Nueva opción
  ];

  // ESTO ES PARA TEST
  const handleResetTables = async () => {
    if (window.confirm("¿Estás seguro de que quieres resetear las tablas? Esto eliminará todos los datos.")) {
      try {
        const response = await axios.post('/reset-tables');
        alert(response.data.message);
      } catch (error) {
        alert("Error al resetear las tablas: " + error.response.data.error);
      }
    }
  };
 // ESTO ES PARA TEST
  return (
    <>
      <Main_sidebar />
      <Box className="config-container">
        <Typography variant="h3" component="div" className="config-header" mb={6} mt={4}>
          Configuración
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {options.map((option, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card
                className="card-conf"
                onClick={() => handleCardClick(index, option.path, option.isModal)}
                style={{ textAlign: 'center', padding: '20px' }}
              >
                <CardContent>
                  {option.icon}
                  <Typography variant="h6" component="div" style={{ marginTop: '10px' }}>
                    {option.title}
                  </Typography>
                </CardContent>
                {option.subOptions && (
                  <Collapse in={openIndex === index}>
                    <List>
                      {option.subOptions.map((subOption, subIndex) => (
                        <ListItem
                          key={subIndex}
                          className="sub-option-item"
                          onClick={() => handleSubOptionClick(subOption.path, subOption.isModal, subIndex, subOption.tipo)}
                        >
                          <ListItemText primary={subOption.title} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} mt={3}>
          <button onClick={handleResetTables}>
            Resetear Tablas
          </button>
        </Grid>
      </Box>
      <ConfigGeneral open={openModal} handleClose={handleCloseModal}/>
      <ResR3 open={openR3} handleClose={() => setOpenR3(false)} subindex={1}/>
      <ResREE open={openREE} handleClose={() => setOpenREE(false)} subindex={2}/>
      <ResRE open={openRE} handleClose={() => setOpenRE(false)} subindex={3}/>
      <ResROSAE open={openROSAE} handleClose={() => setOpenROSAE(false)} subindex={4}/>
      <DatasetModal open={openDatasetModal} handleClose={() => setOpenDatasetModal(false)} /> 
      <GestionModal open={openAlertasModal} handleClose={handleCloseGestionModal} />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </>
  );
};

export default MainConfiguracion;
