import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IconButton, Modal, Box, Button, Grid, Typography, TextField, Tabs, Tab, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { styleModalInmueble } from "../css/modal";
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from 'react-router-dom'; 

const ArchivosModal = ({ open, handleClose, inmueble }) => {
    const [documents, setDocuments] = useState([]);
    const [resolutions, setResolutions] = useState([]);
    const [id_doc, setIdDoc] = useState(0);
    const navigate = useNavigate();

    const [newDocument, setNewDocument] = useState({
        numero_oficio: '',
        asunto: '',
        siglas: '',
        fecha_oficio: '',
        file: null,
        resolution: ''
    });
    const [tabIndex, setTabIndex] = useState(0);
    const [assigningResolution, setAssigningResolution] = useState(false);

    useEffect(() => {
        if (inmueble) {
            axios.get(`https://aleia.in/inmueble-archivos-get/${inmueble.id_inmueble}`)
                .then(response => {
                    if (Array.isArray(response.data)) {
                        setDocuments(response.data);
                    } else {
                        setDocuments([]);
                    }
                })
                .catch(error => {
                    console.error('Error fetching documents:', error);
                });
        }
    }, [inmueble]);

    useEffect(() => {
        axios.get('https://aleia.in/get-all-res')
            .then(response => {
                if (response.data.rows && Array.isArray(response.data.rows)) {
                    setResolutions(response.data.rows);
                } else {
                    setResolutions([]);
                }
            })
            .catch(error => {
                console.error('Error fetching resolutions:', error);
            });
    }, []);

    useEffect(() => {
        if (!open) {
            setNewDocument({
                numero_oficio: '',
                asunto: '',
                siglas: '',
                fecha_oficio: '',
                file: null,
                resolution: ''
            });
            setAssigningResolution(false);
            setIdDoc(null);
            setTabIndex(0);
        }
    }, [open]);

    const handleFileChange = (event) => {
        setNewDocument({ ...newDocument, file: event.target.files[0] });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewDocument({ ...newDocument, [name]: value });
    };

    const handleResolutionChange = (event) => {
        setNewDocument({ ...newDocument, resolution: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('numero_oficio', newDocument.numero_oficio);
        formData.append('asunto', newDocument.asunto);
        formData.append('siglas', newDocument.siglas);
        formData.append('fecha_oficio', newDocument.fecha_oficio);
        formData.append('resolution', newDocument.resolution);

        if (!assigningResolution) { 
            formData.append('file', newDocument.file);
        } else {
            formData.append('id_doc', id_doc); 
        }

        formData.append('assign_resolution', assigningResolution ? 'true' : 'false'); 

        axios.post(`https://aleia.in/upload-file/${inmueble.id_inmueble}`, formData)
            .then(response => {
                setDocuments([...documents, response.data]);
                handleClose();
                navigate(0); 
                // window.location.reload();
            })
            .catch(error => {
                console.error('Error uploading document:', error);
            });
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleAssignResolution = (doc) => {
        const formattedDate = doc.fecha_oficio ?  new Date(doc.fecha_oficio).toISOString().split('T')[0] : '';
        setNewDocument({
            numero_oficio: doc.numero_oficio,
            asunto: doc.asunto,
            siglas: doc.siglas,
            fecha_oficio: formattedDate,
            file: null,
            resolution: ''
        });
        setIdDoc(doc.id_doc)
        setAssigningResolution(true); 
        setTabIndex(1); 
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={styleModalInmueble}>
                <Typography variant="h6" gutterBottom>
                    Documentos del Inmueble
                </Typography>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                    <Tab label="Ver Documentos" />
                    <Tab label={assigningResolution ? "Asignar Resolución" : "Subir Documento"} /> 
                </Tabs>
                {tabIndex === 0 && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {Array.isArray(documents) && documents.map(doc => (
                                <Box key={doc.id} mb={2}>
                                    
                                    <Typography>{doc.id_inmueble}_{doc.siglas}{doc.formato}</Typography>
                                    <IconButton onClick={() => {
                                        const link = document.createElement('a');
                                        link.href = doc.file_url;
                                        link.download = `${doc.id_inmueble}_${doc.siglas}${doc.formato}`;
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    }}
                                      sx={{color:'black'}}
                                    >
                                        <DownloadIcon />
                                    </IconButton>
                                    <Button variant="contained"
                                      sx={{background:'black'}}
                                      onClick={() => handleAssignResolution(doc)}>
                                        Asignar Resolución
                                    </Button>
                                </Box>
                            ))}
                        </Grid>
                    </Grid>
                )}
                {tabIndex === 1 && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    label="Número de oficio"
                                    name="numero_oficio"
                                    fullWidth
                                    value={newDocument.numero_oficio}
                                    onChange={handleInputChange}
                                    margin="normal"
                                    disabled={assigningResolution}
                                    required
                                />
                                <TextField
                                    label="Asunto"
                                    name="asunto"
                                    fullWidth
                                    value={newDocument.asunto}
                                    onChange={handleInputChange}
                                    margin="normal"
                                    disabled={assigningResolution}
                                />
                                <TextField
                                    label="Siglas"
                                    name="siglas"
                                    fullWidth
                                    value={newDocument.siglas}
                                    onChange={handleInputChange}
                                    margin="normal"
                                    disabled={assigningResolution}
                                />
                                <TextField
                                    label="Fecha de expedición"
                                    type="date"
                                    name="fecha_oficio"
                                    value={newDocument.fecha_oficio}
                                    onChange={handleInputChange}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    margin="normal"
                                    disabled={assigningResolution}
                                />
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="resolution-label">Responder con resolución</InputLabel>
                                    <Select
                                        labelId="resolution-label"
                                        value={newDocument.resolution}
                                        onChange={handleResolutionChange}
                                        label="Resolución"
                                    >
                                        {resolutions.map((res) => (
                                            <MenuItem key={res.id} value={res.id}>{res.nombre_res}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {!assigningResolution && (
                                    <IconButton
                                        variant="outlined"
                                        component="label"
                                        sx={{ marginTop: 2, color: 'black' }}
                                    >
                                        <UploadIcon />
                                        <input
                                            type="file"
                                            onChange={handleFileChange}
                                        />
                                    </IconButton>
                                )}
                                <Box mt={2} display="flex" justifyContent="center">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        sx={{background:'black' }}
                                    >
                                        Ingresar
                                    </Button>
                                </Box>
                            </form>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Modal>
    );
};

export default ArchivosModal;
